import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"

import "../fonts/hk-grotesk/index.css"

const normalFonts = [
  "HK Grotesk",
  "Roboto",
  '"Helvetica Neue"',
  '"Segoe UI"',
  "Arial",
  "sans-serif",
].join(",")

const theme = (themeType = null) => {
  let theme = themeType

  if (themeType === null && typeof window !== "undefined") {
    theme =
      localStorage.getItem("theme") === null ||
      localStorage.getItem("theme") === "light"
        ? "light"
        : "dark"
  }

  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        type: theme,
        primary: {
          main: "#720f3b",
        },
        secondary: {
          main: theme === "dark" ? "#6dbde3" : "#960e4a",
        },
      },
      typography: {
        allVariants: { fontFamily: normalFonts },
        fontFamily: normalFonts,
        body1: {
          fontSize: "1.25rem",
        },
        body2: {
          fontSize: "1.14rem",
        },
        caption: {
          fontSize: "0.95rem",
          color: "rgba(255, 255, 255, 0.5)", // 'hint' text colour
          fontStyle: "italic",
        },
        h1: {
          fontSize: "4rem",
          fontWeight: "700",
        },
        h2: {
          fontSize: "3.65rem",
          fontWeight: "600",
        },
        h3: {
          fontWeight: "600",
        },
        h4: {
          fontWeight: "500",
        },
        h5: {
          fontWeight: "500",
        },
        h6: {
          fontWeight: "500",
        },
      },
    }),
    { breakpoints: ["xs", "sm", "md", "lg", "xl"], factor: 2.25 }
  )
}

export default theme
