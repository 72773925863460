import PropTypes from "prop-types"
import React from "react"

import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
} from "@material-ui/core"

import { H6 } from "./EasyText"

import CssBaseline from "@material-ui/core/CssBaseline"

import ToggleThemeIcon from "mdi-react/InvertColorsIcon"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  navbar: {
    minWidth: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: "#fff",
  },
}))

const Header = ({ pageTitle, themeToggle }) => {
  return (
    <>
      <CssBaseline />
      <MakeAppBar themeToggle={themeToggle} title={pageTitle} />
    </>
  )
}

const MakeAppBar = ({ title, themeToggle }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <H6 key={1} className={classes.title}>
            {title}
          </H6>
          <Tooltip title="Toggle light/dark theme" onClick={themeToggle}>
            <IconButton className={classes.icon}>
              <ToggleThemeIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  )
}

MakeAppBar.propTypes = {
  title: PropTypes.string,
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Oathall Leavers 2020`,
  pageTitle: `Oathall Leavers 2020`,
}

export default Header
